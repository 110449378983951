.leaflet-container {
  width: 100%;
  height: 40vh;
}

body {
  background: #ddd;
}

body:after{
content: "beta";
position: fixed;
z-index: 1070;
width: 80px;
height: 20px;
background: rgb(212, 112, 17);
top: 7px;
left: -20px;
text-align: center;
font-size: 12px;
letter-spacing: 1px;
font-family: sans-serif;
text-transform: uppercase;
font-weight: bold;
color: rgb(255, 255, 255);
line-height: 22px;
-ms-transform:rotate(-45deg);
-webkit-transform:rotate(-45deg);
transform:rotate(-45deg);
}